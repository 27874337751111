import { createReducer, on } from '@ngrx/store';
import { toggleVerticalMenu } from './menu.actions';
import { initialState } from './menu.state';

export const menuReducer = createReducer(
  initialState,
  on(toggleVerticalMenu, (state) => ({
    ...state,
    verticalMenuOpen: !state.verticalMenuOpen,
  })),
);
