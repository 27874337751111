import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { PDFGeneratorService } from '../../../core/services/pdf.service';
import { SnackbarType } from '../../../core/services/snackbar/core/snackbar-type.enum';
import { SnackbarComponent } from '../../../core/services/snackbar/snackbar.component';
import { SnackbarService } from '../../../core/services/snackbar/snackbar.service';
import { CourseService } from '../../services/course/course.service';
import { setLoadingInactive } from '../core/core.actions';
import {
  approveRequest,
  approveRequestFailure,
  approveRequestSuccess,
  cancelApprovalRequest,
  cancelApprovalRequestFailure,
  cancelApprovalRequestSuccess,
  copyCourse,
  copyCourseFailure,
  copyCourseSuccess,
  createNewCourseVersion,
  createNewCourseVersionFailure,
  createNewCourseVersionSuccess,
  deleteCourse,
  deleteCourseFailure,
  deleteCourseSuccess,
  deleteCourseVersion,
  deleteCourseVersionFailure,
  deleteCourseVersionSuccess,
  generatePDF,
  generatePDFFailure,
  generatePDFSuccess,
  loadCourse,
  loadCourseFailure,
  loadCourseHistory,
  loadCourseHistoryFailure,
  loadCourseHistorySuccess,
  loadCourses,
  loadCoursesFailure,
  loadCoursesSuccess,
  loadCourseSuccess,
  loadDeletedCourses,
  loadDeletedCoursesFailure,
  loadDeletedCoursesSuccess,
  publishCourse,
  publishCourseFailure,
  publishCourseSuccess,
  rejectApprovalRequest,
  rejectApprovalRequestFailure,
  rejectApprovalRequestSuccess,
  requestApproval,
  requestApprovalFailure,
  requestApprovalSuccess,
  restoreCourse,
  restoreCourseFailure,
  restoreCourseSuccess,
  saveCourse,
  saveCourseFailure,
  saveCourseSuccess,
  unapprove,
  unapproveFailure,
  unapproveSuccess,
  unpublishCourse,
  unpublishCourseFailure,
  unpublishCourseSuccess,
} from './course.actions';

@Injectable()
export class CourseEffects {
  private courseService = inject(CourseService);
  private pdfGeneratorService = inject(PDFGeneratorService);
  private router = inject(Router);
  private actions$ = inject(Actions);
  private snackbar = inject(SnackbarService);
  private store = inject(Store);

  saveCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveCourse),
      exhaustMap((action) =>
        this.courseService
          .SaveCourse({
            id: action.course.id,
            courseVersionId: action.course.courseVersionId,
            title: action.course.title,
            externalUrl: action.course.externalUrl || '',
            author: action.course.author.name,
            categoryId: action.course.category.id,
            subcategoryId: action.course.subcategory.id,
            subcategoryName: action.course.subcategory.name,
            languageId: action.course.language.id,
          })
          .pipe(
            map((res) => {
              return saveCourseSuccess({
                courseId: res.courseId,
                versionNumber: res.versionNumber,
              });
            }),
            catchError((error) =>
              of(saveCourseFailure({ error: error.error })),
            ),
          ),
      ),
    ),
  );

  saveCourseSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveCourseSuccess),
        tap((action) => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Success,
            text: 'snackbar.courseSaved',
          });
          if (this.router.url.includes('create')) {
            this.router.navigate(['/', 'course', action.courseId, 'edit'], {
              queryParams: { versionNumber: action.versionNumber || 1 },
            });
          } else {
            this.store.dispatch(
              loadCourse({
                id: action.courseId,
                versionNumber: action.versionNumber || 1,
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  saveCourseFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveCourseFailure),
        tap((action) => {
          console.error(action.error);

          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `snackbar.saveCourseFailed`,
          });
          window.location.reload();
        }),
      ),
    { dispatch: false },
  );

  copyCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copyCourse),
      exhaustMap((action) =>
        this.courseService.CopyCourse(action.course).pipe(
          map(() => copyCourseSuccess()),
          catchError((error) => of(copyCourseFailure(error.error))),
        ),
      ),
    ),
  );

  copyCourseSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copyCourseSuccess),
      tap(() => {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Success,
          text: 'snackbar.courseCopied',
        });
        this.router.navigate(['/', 'course-list', 'editor']);
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  copyCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copyCourseFailure),
      tap((action) => {
        console.error(action.error);
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.copyCourseFailed`,
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  requestApprovalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestApproval),
      exhaustMap((action) =>
        this.courseService.RequestApproval(action.versionId).pipe(
          map(() => requestApprovalSuccess()),
          catchError((error) =>
            of(requestApprovalFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  requestApprovalSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(requestApprovalSuccess),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Success,
            text: 'snackbar.approvalRequested',
          });
          this.router.navigate(['/', 'course-list', 'editor']);
        }),
      ),
    { dispatch: false },
  );

  requestApprovalFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestApprovalFailure),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  cancelApprovalRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelApprovalRequest),
      exhaustMap((action) =>
        this.courseService.CancelApproval(action.courseVersionId).pipe(
          map(() => cancelApprovalRequestSuccess()),
          catchError((error) =>
            of(cancelApprovalRequestFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  cancelApprovalRequestSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelApprovalRequestSuccess),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Success,
            text: 'snackbar.approvalRequestCancelled',
          });
          this.router.navigate(['/', 'course-list', 'editor']);
        }),
      ),
    { dispatch: false },
  );

  cancelApprovalRequestFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelApprovalRequestFailure),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  unapproveEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unapprove),
      exhaustMap((action) =>
        this.courseService.Unapprove(action.courseVersionId).pipe(
          map(() => unapproveSuccess()),
          catchError((error) => of(unapproveFailure({ error: error.error }))),
        ),
      ),
    ),
  );

  unapproveSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unapproveSuccess),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Success,
            text: 'snackbar.courseUnapproved',
          });
          this.router.navigate(['/', 'course-list', 'editor']);
        }),
      ),
    { dispatch: false },
  );

  unapproveFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unapproveFailure),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  createNewCourseVersionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewCourseVersion),
      exhaustMap((action) =>
        this.courseService.CreateNewCourseVersion(action.newCourseVersion).pipe(
          map(() =>
            createNewCourseVersionSuccess({
              newCourseVersion: action.newCourseVersion.latestCourseVersion + 1,
            }),
          ),
          catchError((error) =>
            of(createNewCourseVersionFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  createNewCourseVersionSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewCourseVersionSuccess),
      tap((action) => {
        const url = this.router.url.slice(0, -1) + action.newCourseVersion;
        this.router.navigateByUrl(url).then(() => {
          window.location.reload();
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  loadCoursesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCourses),
      exhaustMap((action) =>
        this.courseService
          .GetCoursesByUser(action.mode, action.languageId)
          .pipe(
            map((items) => loadCoursesSuccess({ courses: items })),
            catchError((error) =>
              of(loadCoursesFailure({ error: error.error })),
            ),
          ),
      ),
    ),
  );

  loadCoursesFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadCoursesFailure),
        tap((action) => {
          console.error(action.error);
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `snackbar.loadCoursesFailed`,
          });
        }),
      ),
    { dispatch: false },
  );

  loadDeletedCoursesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDeletedCourses),
      exhaustMap(() =>
        this.courseService.GetDeletedCourses().pipe(
          map((items) => loadDeletedCoursesSuccess({ courses: items })),
          catchError((error) => of(loadCoursesFailure({ error: error.error }))),
        ),
      ),
    ),
  );

  loadDeletedCoursesFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadDeletedCoursesFailure),
        tap((action) => {
          console.error(action.error);
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `snackbar.loadCoursesFailed`,
          });
        }),
      ),
    { dispatch: false },
  );

  loadCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCourse),
      exhaustMap((action) =>
        this.courseService.GetCourseById(action.id, action.versionNumber).pipe(
          map((course) => {
            return loadCourseSuccess({ course });
          }),
          catchError((error) => {
            return of(loadCourseFailure({ error: error.error }));
          }),
        ),
      ),
    ),
  );

  loadCourseFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadCourseFailure),
        tap((action) => {
          console.error(action.error);
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `snackbar.loadCourseFailed`,
          });
          this.router.navigate(['/', 'course-list', 'editor']);
        }),
      ),
    { dispatch: false },
  );

  loadCourseHistoryByIdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCourseHistory),
      exhaustMap((action) =>
        this.courseService.GetCourseHistoryById(action.id).pipe(
          map((courseVersions) => loadCourseHistorySuccess({ courseVersions })),
          catchError((error) =>
            of(loadCourseHistoryFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  loadCourseHistoryFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadCourseHistoryFailure),
        tap((action) => {
          console.error(action.error);
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `snackbar.loadCourseHistoryFailed`,
          });
        }),
      ),
    { dispatch: false },
  );

  deleteCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCourse),
      exhaustMap((action) =>
        this.courseService.DeleteCourse(action.id).pipe(
          map(() => deleteCourseSuccess()),
          catchError((error) =>
            of(
              deleteCourseFailure({
                error: error.error,
                courseId: action.id,
                versionNumber: action.versionNumber,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  deleteCourseSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteCourseSuccess),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Success,
            text: 'snackbar.courseDeleted',
          });
          this.router.navigate(['/', 'course-list', 'editor']);
        }),
      ),
    { dispatch: false },
  );

  deleteCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCourseFailure),
      tap((action) => {
        console.error(action.error);
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.deleteCourseFailed`,
        });
      }),
      exhaustMap((action) => [
        loadCourse({
          id: action.courseId,
          versionNumber: action.versionNumber,
        }),
      ]),
    ),
  );

  deleteCourseVersionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCourseVersion),
      exhaustMap((action) =>
        this.courseService.DeleteCourseVersion(action.courseVersionId).pipe(
          map(() => deleteCourseVersionSuccess()),
          catchError((error) =>
            of(deleteCourseVersionFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  deleteCourseVersionSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteCourseVersionSuccess),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Success,
            text: 'snackbar.courseVersionDeleted',
          });
          window.location.reload();
        }),
      ),
    { dispatch: false },
  );

  deleteCourseVersionFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCourseVersionFailure),
      tap((action) => {
        console.error(action.error);
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.deleteCourseVersionFailed`,
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  restoreCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreCourse),
      exhaustMap((action) =>
        this.courseService.RestoreCourse(action.id).pipe(
          map(() => restoreCourseSuccess({ id: action.id })),
          catchError((error) =>
            of(restoreCourseFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  restoreCourseSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(restoreCourseSuccess),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Success,
            text: 'snackbar.courseRestored',
          });
        }),
      ),
    { dispatch: false },
  );

  restoreCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreCourseFailure),
      tap((action) => {
        console.error(action.error);
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.restoreCourseFailed`,
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  rejectApprovalRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectApprovalRequest),
      exhaustMap((action) =>
        this.courseService
          .RejectApprovalRequest(
            action.courseVersionId,
            action.rejectionComment,
          )
          .pipe(
            map(() => rejectApprovalRequestSuccess()),
            catchError((error) =>
              of(rejectApprovalRequestFailure({ error: error.error })),
            ),
          ),
      ),
    ),
  );

  rejectApprovalRequestSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectApprovalRequestSuccess),
      tap(() => {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Success,
          text: 'snackbar.approvalRequestRejected',
        });
        this.router.navigate(['/', 'course-list', 'approver']);
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  rejectApprovalRequestFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectApprovalRequestFailure),
      tap((action) => {
        console.error(action.error);
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.approvalRequestRejectionFailed`,
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  approveCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveRequest),
      exhaustMap((action) =>
        this.courseService.ApproveRequest(action.courseVersionId).pipe(
          map(() => approveRequestSuccess()),
          catchError((error) =>
            of(approveRequestFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  approveCourseSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(approveRequestSuccess),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Success,
            text: 'snackbar.approvalRequestApproved',
          });
          this.router.navigate(['/', 'course-list', 'approver']);
        }),
      ),
    { dispatch: false },
  );

  approveCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveRequestFailure),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  publishCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishCourse),
      exhaustMap((action) =>
        this.courseService.Publish(action.courseVersionId).pipe(
          map(() => publishCourseSuccess()),
          catchError((error) =>
            of(publishCourseFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  publishCourseSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(publishCourseSuccess),
        tap(() => {
          this.router.navigate(['/', 'course-list', 'viewer']);
        }),
      ),
    { dispatch: false },
  );

  publishCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishCourseFailure),
      tap((action) => {
        console.error(action.error);
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.publishCourseFailed`,
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  unpublishCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unpublishCourse),
      exhaustMap((action) =>
        this.courseService.Unpublish(action.courseVersionId).pipe(
          map(() => unpublishCourseSuccess()),
          catchError((error) =>
            of(unpublishCourseFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  unpublishCourseSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unpublishCourseSuccess),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Success,
            text: 'snackbar.courseUnpublished',
          });
          this.router.navigate(['/', 'course-list', 'editor']);
        }),
      ),
    { dispatch: false },
  );

  unpublishCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unpublishCourseFailure),
      tap((action) => {
        console.error(action.error);
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.unpublishCourseFailed`,
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  generatePDFEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generatePDF),
      exhaustMap((action) =>
        this.pdfGeneratorService
          .generate(action.slides, action.courseTitle)
          .pipe(
            map(() => generatePDFSuccess()),
            catchError(() => of(generatePDFFailure())),
          ),
      ),
    ),
  );

  generatePDFSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generatePDFSuccess),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  generatePDFFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generatePDFFailure),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );
}
