import { CourseDetailsDto } from '../../../core/dtos/couse-details.dto';
import { CourseVersion } from '../../../core/models/course-version.model';
import { Course, DeletedCourse } from '../../../core/models/course.model';

export interface CourseState {
  courses?: Course[];
  currentCourse: CourseDetailsDto | null;
  courseHistory?: CourseVersion[];
  isLoading: boolean;
  saveSuccess?: boolean;
  deletedCourses?: DeletedCourse[];
}

export const initialState: CourseState = {
  currentCourse: null,
  isLoading: false,
};
