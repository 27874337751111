import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TemplateTypes } from '../../../core/definitions/template-types.definition';
import { CourseDetailsDto } from '../../../core/dtos/couse-details.dto';
import { CourseDetails } from '../../../core/models/course-details.model';
import { CourseVersion } from '../../../core/models/course-version.model';
import { Course, DeletedCourse } from '../../../core/models/course.model';
import { NewCourseVersion } from '../../../core/models/new-course-version.model';
import { Slide } from '../../../core/models/slide.model';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private http = inject(HttpClient);

  GetCoursesByUser(mode: string, languageId: number): Observable<Course[]> {
    return this.http
      .get<
        Course[]
      >(`${environment.backendApiUrl}/GetCoursesByUser?code=${environment.apiKey}&mode=${mode}&languageId=${languageId}`)
      .pipe(
        map((response) => response),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetCourseById(
    id: number,
    versionNumber: number,
  ): Observable<CourseDetailsDto> {
    return this.http
      .get<CourseDetailsDto>(
        `${environment.backendApiUrl}/GetCourseById?id=${id}&versionNumber=${versionNumber}&code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          const list = [] as Slide[];

          for (let i = 0; i < response.slides.length; i++) {
            const element = response.slides[i];

            const item = element;

            if (element.templateName) {
              item.template = TemplateTypes.getDynamicComponentType(
                element.templateName,
              );
              item.templateImageUrl = TemplateTypes.getTemplateThumbnailUrl(
                element.templateName,
              );
            }
            list.push(item);
          }
          return {
            ...response,
            slides: list,
          } as CourseDetailsDto;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetCourseHistoryById(id: number): Observable<CourseVersion[]> {
    return this.http
      .get<
        CourseVersion[]
      >(`${environment.backendApiUrl}/GetCourseHistoryById?id=${id}&code=${environment.apiKey}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetDeletedCourses(): Observable<DeletedCourse[]> {
    return this.http
      .get<
        DeletedCourse[]
      >(`${environment.backendApiUrl}/GetDeletedCourses?code=${environment.apiKey}`)
      .pipe(
        map((response) => response),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveCourse(course: {
    id: number;
    courseVersionId: number;
    title: string;
    externalUrl: string;
    author: string;
    categoryId: number;
    subcategoryId: number;
    subcategoryName: string;
    languageId: number;
  }): Observable<{ courseId: number; versionNumber: number }> {
    return this.http
      .post<{
        courseId: number;
        versionNumber: number;
      }>(
        `${environment.backendApiUrl}/SaveCourse?id=${course.id}&code=${environment.apiKey}`,
        { course },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  CopyCourse(course: CourseDetails): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/CopyCourse?code=${environment.apiKey}`,
        { course: course },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteCourse(id: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteCourse?id=${id}&code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  RestoreCourse(id: number): Observable<object> {
    return this.http
      .get(
        `${environment.backendApiUrl}/RestoreCourse?id=${id}&code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  CreateNewCourseVersion(
    newCourseVersion: NewCourseVersion,
  ): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/CreateCourseVersion?code=${environment.apiKey}`,
        { newCourseVersion },
      )
      .pipe(
        map((response) => {
          console.log('new version response', response);

          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteCourseVersion(courseVersionId: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteCourseVersion?courseVersionId=${courseVersionId}&code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  RequestApproval(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/RequestApproval/${courseVersionId}?code=${environment.apiKey}`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  CancelApproval(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/CancelApproval/${courseVersionId}?code=${environment.apiKey}`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  ApproveRequest(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/ApproveRequest/${courseVersionId}?code=${environment.apiKey}`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  Unapprove(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/Unapprove/${courseVersionId}?code=${environment.apiKey}`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  RejectApprovalRequest(
    courseVersionId: number,
    rejectionComment: string,
  ): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/RejectApprovalRequest/${courseVersionId}?code=${environment.apiKey}`,
        {
          rejectionComment: rejectionComment,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  Publish(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/Publish/${courseVersionId}?code=${environment.apiKey}`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  Unpublish(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/Unpublish/${courseVersionId}?code=${environment.apiKey}`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
