import { createAction, props } from '@ngrx/store';
import { CourseDetailsDto } from '../../../core/dtos/couse-details.dto';
import { Modes } from '../../../core/enums/modes';
import { CourseDetails } from '../../../core/models/course-details.model';
import { CourseVersion } from '../../../core/models/course-version.model';
import { Course, DeletedCourse } from '../../../core/models/course.model';
import { NewCourseVersion } from '../../../core/models/new-course-version.model';
import { Slide } from '../../../core/models/slide.model';

export const loadCourses = createAction(
  '[Course] Load all Courses',
  props<{ mode: Modes; languageId: number }>(),
);

export const loadCoursesSuccess = createAction(
  '[Course] Load all Courses Success',
  props<{ courses: Course[] }>(),
);

export const loadCoursesFailure = createAction(
  '[Course] Load all Courses Failure',
  props<{ error: string }>(),
);

export const loadDeletedCourses = createAction('[Course] Load deleted courses');

export const loadDeletedCoursesSuccess = createAction(
  '[Course] Load deleted courses Success',
  props<{ courses: DeletedCourse[] }>(),
);

export const loadDeletedCoursesFailure = createAction(
  '[Course] Load deleted courses Failure',
  props<{ error: string }>(),
);

export const loadCourse = createAction(
  '[Course] Load Course',
  props<{ id: number; versionNumber: number }>(),
);

export const loadCourseSuccess = createAction(
  '[Course] Load Course Success',
  props<{ course: CourseDetailsDto }>(),
);

export const loadCourseFailure = createAction(
  '[Course] Load Course Failure',
  props<{ error: string }>(),
);

export const unloadCourse = createAction('[Course] Unload current Course');

export const loadCourseHistory = createAction(
  '[Course] Load Course History',
  props<{ id: number }>(),
);

export const loadCourseHistorySuccess = createAction(
  '[Course] Load Course History Success',
  props<{ courseVersions: CourseVersion[] }>(),
);

export const loadCourseHistoryFailure = createAction(
  '[Course] Load Course History Failure',
  props<{ error: string }>(),
);

export const saveCourse = createAction(
  '[Course] Save Course',
  props<{ course: CourseDetails }>(),
);

export const saveCourseSuccess = createAction(
  '[Course] Save Course Success',
  props<{ courseId: number; versionNumber: number }>(),
);

export const saveCourseFailure = createAction(
  '[Course] Save Course Failure',
  props<{ error: string }>(),
);

export const copyCourse = createAction(
  '[Course] Copy Course',
  props<{ course: CourseDetails }>(),
);

export const copyCourseSuccess = createAction('[Course] Copy Course Success');

export const copyCourseFailure = createAction(
  '[Course] Copy Course Failure',
  props<{ error: string }>(),
);

export const requestApproval = createAction(
  '[Course] Request Approval for Course version',
  props<{ versionId: number }>(),
);

export const requestApprovalSuccess = createAction(
  '[Course] Request Approval for Course Version Success',
);

export const requestApprovalFailure = createAction(
  '[Course] Request Approval for Course Version Failure',
  props<{ error: string }>(),
);

export const cancelApprovalRequest = createAction(
  '[Course] Cancel Approval Request for Course version',
  props<{ courseVersionId: number }>(),
);

export const cancelApprovalRequestSuccess = createAction(
  '[Course] Cancel Approval Request for Course Version Success',
);

export const cancelApprovalRequestFailure = createAction(
  '[Course] Cancel Approval Request for Course Version Failure',
  props<{ error: string }>(),
);

export const unapprove = createAction(
  '[Course] Un-Approve for Course version',
  props<{ courseVersionId: number }>(),
);

export const unapproveSuccess = createAction(
  '[Course] Un-Approve for Course Version Success',
);

export const unapproveFailure = createAction(
  '[Course] Un-Approve for Course Version Failure',
  props<{ error: string }>(),
);

export const createNewCourseVersion = createAction(
  '[Course] Create new Course version',
  props<{ newCourseVersion: NewCourseVersion }>(),
);

export const createNewCourseVersionSuccess = createAction(
  '[Course] Create new Course Version Success',
  props<{ newCourseVersion: number }>(),
);

export const createNewCourseVersionFailure = createAction(
  '[Course] Create new Course Version Failure',
  props<{ error: string }>(),
);

export const deleteCourse = createAction(
  '[Course] Delete Course',
  props<{ id: number; versionNumber: number }>(),
);

export const deleteCourseSuccess = createAction('[Course] Delete Success');

export const deleteCourseFailure = createAction(
  '[Course] Delete Failure',
  props<{ error: string; courseId: number; versionNumber: number }>(),
);

export const deleteCourseVersion = createAction(
  '[Course] Delete Course Version',
  props<{ courseVersionId: number }>(),
);

export const deleteCourseVersionSuccess = createAction(
  '[Course] Delete Course Version Success',
);

export const deleteCourseVersionFailure = createAction(
  '[Course] Delete Course Version Failure',
  props<{ error: string }>(),
);

export const restoreCourse = createAction(
  '[Course] Restore Course',
  props<{ id: number }>(),
);

export const restoreCourseSuccess = createAction(
  '[Course] Restore Course Success',
  props<{ id: number }>(),
);

export const restoreCourseFailure = createAction(
  '[Course] Restore Course Failure',
  props<{ error: string }>(),
);

export const approveRequest = createAction(
  '[Course] Approve request',
  props<{ courseVersionId: number }>(),
);

export const approveRequestSuccess = createAction(
  '[Course] Approve request Success',
);

export const approveRequestFailure = createAction(
  '[Course] Approve request Failure',
  props<{ error: string }>(),
);

export const rejectApprovalRequest = createAction(
  '[Course] Reject approval request',
  props<{ courseVersionId: number; rejectionComment: string }>(),
);

export const rejectApprovalRequestSuccess = createAction(
  '[Course] Reject approval request Success',
);

export const rejectApprovalRequestFailure = createAction(
  '[Course] Reject approval request Failure',
  props<{ error: string }>(),
);

export const publishCourse = createAction(
  '[Course] Publish Course',
  props<{ courseVersionId: number }>(),
);

export const publishCourseSuccess = createAction(
  '[Course] Publish Course Success',
);

export const publishCourseFailure = createAction(
  '[Course] Publish Course Failure',
  props<{ error: string }>(),
);

export const unpublishCourse = createAction(
  '[Course] Unpublish Course',
  props<{ courseVersionId: number }>(),
);

export const unpublishCourseSuccess = createAction(
  '[Course] Unpublish Course Success',
);

export const unpublishCourseFailure = createAction(
  '[Course] Unpublish Course Failure',
  props<{ error: string }>(),
);

export const generatePDF = createAction(
  '[Course] Generate PDF',
  props<{ courseTitle: string; slides: Slide[] }>(),
);

export const generatePDFSuccess = createAction('[Course] Generate PDF Success');

export const generatePDFFailure = createAction('[Course] Generate PDF Failure');
